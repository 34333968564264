import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';
import GlobalStyle from "./GlobalStyles";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import SocialButton from "./components/SocialButton";

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Navbar />
      <SocialButton />
      <Routes>
        <Route path="/" exact element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
