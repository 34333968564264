import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { CursiveTitle, MainContainer } from "../GlobalStyles";
import { HomeStyles } from "../styles/Home.style";
import FormComponent from "../components/FormComponent";

const Home = () => {
  return (
    <div>
      <HomeStyles>
        <MainContainer>
          <div className="row">
            <div className="left-container col-md-6">
              <CursiveTitle style={{ textAlign: "center" }}>
                We're making things more awesome.
              </CursiveTitle>
              <div className="mid-text">Be back soon</div>
              <p className="regular-text">
                Our website is undergoing improvements by our technical team.
                Please provide your email address, and we'll promptly notify you
                once it's live. Thank you for your patience and interest!
              </p>
              <div className="message-wrap">
                <form action="">
                  <div className="form-wrap">
                    <input
                      type="text"
                      className="input-area"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="form-wrap btn-wrap">
                    <input
                      type="text"
                      className="input-area"
                      placeholder="Enter Your Email Address"
                    />
                    <button className="action-btn">Yes, I'm in!</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="right-container col-md-6">
              <div className="inner-container">
                <div className="upper-container">
                  <div className="strong-tagline">
                    Handcrafted, customized gifts for those you cherish the
                    most
                  </div>
                  <div className="regular-text">
                    We're a dedicated team of skilled artisans specializing in
                    paper quilled gift cards and personalized gift combos. With
                    meticulous attention to detail and a passion for creativity,
                    we handcraft unique treasures that beautifully express your
                    sentiments, making every occasion memorable and heartfelt.
                    Welcome to our world of artisanal gifting delights!
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="img-wrap">
                        <img src="../../images/sample-2.jpg" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="img-wrap">
                        <img src="../../images/sample-4.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="review-container">
                  <div className="title-wrap">
                    We're thrilled to announce our expansion to Australia!
                    Exciting times ahead!
                  </div>
                  <div className="img-wrap">
                    <img src="../../images/australia.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainContainer>
      </HomeStyles>
    </div>
  );
};

export default Home;
