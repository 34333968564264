import React from 'react';
import styled from "styled-components";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";




export const SocialButtonStyles = styled.div`
    position: fixed;
    right: 0;
    top: 45%;
    z-index: 9999;
    background-color: #fc006f;
    padding: 25px 10px 25px 15px;

    .btn-wrap {
        padding: 0;
        margin: 0;

        .btn-link {
            text-decoration: none;
            list-style: none;
            padding: 1rem 0;
            border-bottom: solid 1px rgba(255, 255, 255, 0.6);

            &:last-child {
                padding: 1rem 0 0 0;
                border-bottom: none;
            }

            &:first-child {
                padding-top: 0;
            }

            a {
                /* color: #fc006f; */
                text-decoration: none;

                .social-icon {
                    color: #fff;
                    font-size: 1.5rem;

                    &:hover {
                        transform: scale(1.2,1.2);
                    }
                }
            }
        }
    }
`;

const SocialButton = () => {
    return (
        <SocialButtonStyles>
            <ul className="btn-wrap">
                <li className="btn-link">
                    <a 
                        href='https://www.facebook.com/wishkarde'
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <FaFacebookF className='social-icon' />
                    </a>
                </li>
                <li className="btn-link">
                    <a 
                        href='https://www.artographertacts.com/'
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <AiFillInstagram className='social-icon' />
                    </a>
                </li>
                <li className="btn-link">
                    <a 
                        href='https://wa.me/919718058426'
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <IoLogoWhatsapp className='social-icon' />
                    </a>
                </li>
            </ul>
        </SocialButtonStyles>
    );
};

export default SocialButton;