import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterStyles = styled.div`
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-top: 1rem;
  margin-top: 2rem;

  .top-container {
    padding: 20px 0 15px 0;
    /* background-color: #fc006f; */
    /* background-color: var(--primary-color); */
    margin-bottom: 5px;
    font-size: .9rem;
    font-weight: 500;
    text-transform: uppercase;

    .footer-link-wrap {
      display: flex;
      justify-content: center;

      .list-item {
        list-style: none;

        .footer-link {
          text-decoration: none;
          /* color: var(--light-color); */
          /* color: #fff;    */
          color: #fc006f;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .divider {
        margin: 0 1rem;
        color: #fc006f;
      }
    }
  }

  .lower-container {
    font-size: 0.9rem;
    padding: 8px 50px 5px 50px;
    display: flex;
    justify-content: space-between;
    background-color: #fc006f;
    color: #fff;

    .right-content {
      a {
        color: #fff;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterStyles>
      <div className="top-container">
        <ul className="footer-link-wrap">
          <li className="list-item">
            <Link to="/" className="footer-link">Home</Link>
          </li>
          <div className="divider">|</div>
          <li className="list-item">
            <Link to="/" className="footer-link">About</Link>
          </li>
          <div className="divider">|</div>
          <li className="list-item">
            <Link to="/" className="footer-link">Cards</Link>
          </li>
          <div className="divider">|</div>
          <li className="list-item">
            <Link to="/" className="footer-link">Combo</Link>
          </li>
          <div className="divider">|</div>
          <li className="list-item">
            <Link to="/" className="footer-link">Contact</Link>
          </li>
        </ul>
      </div>
      <div className="lower-container">
        <div className="left-content">
          Copyright 2024 © WishKarde. All rights reserved.
        </div>
        <div className="right-content">
          Designed & developed by&nbsp;
          <a
            href="https://www.artographertacts.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ArtographerTacts
          </a>
          .
        </div>
      </div>
    </FooterStyles>
  );
};

export default Footer;
