import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavbarStyle } from '../styles/Navbar.style';

const Navbar = () => {

    const HandleClick = (e) => {
        setToggleState(e);
    }

    const [toggleState, setToggleState] = useState(1);

    return (
        <NavbarStyle>
            <div className='navbar-inner-container'>
                <div className="top-container">
                    <div className="logo-wrap">
                        <img src="../../images/logo_wk-2.png" alt="wishkarde" className="logo" />
                    </div>
                </div>
                <div className="lower-container">
                    <ul className='nav-links-wrap'>
                        <li className="list-item">
                            <Link to='/'
                                className={toggleState === 1 ? 'nav-link active' : 'nav-link'}
                                onClick={() => HandleClick(1)}
                            >
                                Home
                            </Link>
                        </li>
                        <div className="link-divider">
                            |
                        </div>
                        <li className="list-item">
                            <Link to='/'
                                className={toggleState === 2 ? 'nav-link active' : 'nav-link'}
                                onClick={() => HandleClick(2)}
                            >
                                About
                            </Link>
                        </li>
                        <div className="link-divider">
                            |
                        </div>
                        <li className="list-item">
                            <Link to='/'
                                className={toggleState === 3 ? 'nav-link active' : 'nav-link'}
                                onClick={() => HandleClick(3)}
                            >
                                Cards
                            </Link>
                        </li>
                        <div className="link-divider">
                            |
                        </div>
                        <li className="list-item">
                            <Link to='/'
                                className={toggleState === 4 ? 'nav-link active' : 'nav-link'}
                                onClick={() => HandleClick(4)}
                            >
                                Combo
                            </Link>
                        </li>
                        <div className="link-divider">
                            |
                        </div>
                        <li className="list-item">
                            <Link to='/'
                                className={toggleState === 5 ? 'nav-link active' : 'nav-link'}
                                onClick={() => HandleClick(5)}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </NavbarStyle>
    );
}

export default Navbar;
