import styled from "styled-components";

export const HomeStyles = styled.div`

    .left-container {
        padding-top: 6rem;

        .mid-text {
            font-size: 1.3rem;
            margin-top: 1rem;
            text-transform: uppercase;
        }

        .regular-text {
            font-size: 1rem;
            line-height: 1.3rem;
            margin-top: 1rem;
        }

        .message-wrap {
            margin-top: 1.5rem;
            display: flex;
            justify-content: center;

            .form-wrap {
                margin-bottom: 1rem;
                width: 25rem;
                height: 3rem;
                border: solid 1px #fc006f;

                .input-area {
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    padding-left: 1rem;
                }
            }

            .btn-wrap {
                margin-bottom: 0;
                display: flex;

                .input-area {
                    width: 70%;
                }

                .action-btn {
                    width: 30%;
                    color: #fff;
                    background-color: #fc006f;
                    border: none;
                    outline: none;
                }
            }
        }
    }

    .right-container {

        .inner-container {

            .upper-container {
                margin-bottom: 2rem;

                .strong-tagline {
                    font-family: "Yeseva One", serif;
                    text-align: left;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-weight: 500;
                    margin-bottom: .5rem;
                }

                .regular-text {
                    text-align: left;
                    margin-bottom: 1rem;
                }

                .img-wrap {
                    width: 100%;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            
            .review-container {
                margin-bottom: 2rem;

                .title-wrap {
                    text-align: left;
                    font-size: 1rem;
                    font-weight: 700;
                    margin-bottom: .5rem;
                }

                .img-wrap {
                    width: 100%;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
`