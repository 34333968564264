import styled from "styled-components";

export const FormComponentStyles = styled.div`
    text-align: left;
    margin-top: 2rem;
    padding-bottom: 5rem;

    .input-wrap {
        margin-bottom: 1.5rem;

        .input-label {

            span {
                color: #fc006f;
            }
        }
        .input-area {
            display: block;
            width: 20rem;
            outline: none;
            padding: 0.2rem .5rem;
        }
        .text-area {
            height: 6rem;
        }
    }
    .submit-btn-wrap {
        margin-top: 2rem;
        
        .submit-btn {
            background-color: #fc006f;
            outline: none;
            border: none;
            padding: 0.4rem 2rem ;
            color: #fff;
        }
    }
`;

