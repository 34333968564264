import styled from "styled-components";


export const NavbarStyle = styled.nav`

    .navbar-inner-container {
        padding: 20px 0;

        .top-container {
            text-align: center;

            .logo-wrap {
                margin: 1rem 0;

                .logo {
                    height: 35px;
                    width: auto;
                }
            }
        }

        .lower-container {
            display: flex;
            justify-content: center;
            /* background-color: var(--primary-color); */
            background-color: #fc006f;
            /* color: var(--light-color); */
            color: #fff;
            margin-top: 15px;
            padding: 15px 0;
            font-size: .9rem;
            font-weight: 500;
            text-transform: uppercase;

            .nav-links-wrap {
                display: flex;
                margin: 0;
                padding: 0;

                .list-item {
                    text-decoration: none;
                    list-style: none;

                    .nav-link {
                        color: var(--light-color);
                    }

                    .active {
                        color: var(--primary-color);
                        /* color: blue; */
                    }
                }
                .link-divider {
                    margin: 0 1rem;
                }
            }
        }
    }
`;